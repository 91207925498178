import * as React from 'react';
import { getTemplateSrv, config } from '@grafana/runtime';
import { useTheme2 } from '@grafana/ui';
import { AppState, SimpleOptions, IsoClassificationsProps } from './types';
import { TranslationProps } from '../translation';
import { TimeRange } from '@grafana/data';
import { NotificationError } from 'components/shared/NotificationMessage';

const getTemplateCurrentText = (text: string) => {
  const getTemplate = getTemplateSrv().getVariables() as any;
  const template = getTemplate && getTemplate.filter((template: { name: string }) => template.name === text);
  if (!template.length) {
    NotificationError({ message: `Template ${text} not found` });
    return;
  }
  return template[0].current?.value;
};

/**
 * Initialize default state and pass it to the main app component
 */

export const withData = (
  WrappedComponent: React.ComponentType<React.PropsWithChildren<{ initialState: AppState }>>
) => {
  // eslint-disable-next-line react/display-name
  return ({ children }: React.PropsWithChildren<{}>) => {
    const userPath = config.bootData.user;
    const langTemplate = getTemplateCurrentText('lang_selection');
    const macAddress = getTemplateCurrentText('beacon_selection');
    const imageFile = getTemplateCurrentText('imageFile');
    const lengthVariable = getTemplateCurrentText('length_unit');

    const currentTheme = useTheme2();
    let langSelect = '';

    if (!langTemplate) {
      langSelect = 'EN';
    } else {
      langSelect = langTemplate as string;
    }

    const defaultState = {
      country: langSelect,
      dico: {} as TranslationProps,
      user: {
        isGrafanaAdmin: userPath.isGrafanaAdmin,
        orgName: userPath.orgName,
        orgId: userPath.orgId,
        orgRole: userPath.orgRole,
      },
      dashboard: {
        name: '',
        tags: [],
        id: null,
      },
      theme: currentTheme,
      isDicoLoading: true,
      fileImageName: imageFile as string,
      datasourceMysql: [],
      datasourceInflux: [],
      datasourceJson: [],
      panelData: [],
      panelOptions: {} as SimpleOptions,
      timeRangePanel: {} as TimeRange,
      userTrainingZones: [],
      totalSelectedPoints: [],
      macAddress: macAddress as string,
      identityCard: [],
      alerts: [],
      currentSession: { id: 0, status: '' },
      selectedSession: { id: 0, uiId: 0, status: '', isLast: false },
      selectedPointDate: '',
      temperatureUnit: 'celsius',
      lengthUnit: lengthVariable,
      timeZone: '',
      analysisProfile: '',
      statisticsQuery: '',
      isoClassifications: {} as IsoClassificationsProps,
    };

    const [initialState] = React.useState<AppState>(defaultState);
    return <WrappedComponent initialState={initialState}>{children}</WrappedComponent>;
  };
};
